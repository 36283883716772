<template>
  <div class="page-container operation-type-page">
    <portal to="page-name">Tipo de operação</portal>
    <div class="loading loading-lg mt-2" v-if="loading"></div>
    <div class="card card-page" v-else>
      <div class="card-header">
        <div class="form-group float-right status-toggle">
          <label class="form-switch">
            <input type="checkbox" v-model="form.active">
            <i class="form-icon"></i> Registro ativo
          </label>
        </div>
        <div class="card-title">
          Tipo de operação
          <span v-if="form.name"> - </span>
          <small class="text-primary">{{ form.name }}</small>
        </div>
      </div>
      <div class="card-body">
        <div class="columns form-group">
          <div class="column col-6 col-md-12 col-sm-12 form-group"
               :class="{'has-error': $v.form.name.$error}">
            <label for="name" class="form-label">Nome</label>
            <input type="text" id="name" name="name"
                   v-model="form.name" class="form-input"
                   @blur="$v.form.name.$touch()"
                   placeholder="Nome" autocomplete="nope">
            <template v-if="$v.form.name.$error">
              <div class="form-input-hint"
                   v-if="!$v.form.name.required">Campo obrigatório</div>
            </template>
          </div>
          <div class="column col-3 col-md-6 col-sm-12 form-group">
            <label for="type" class="form-label">Tipo</label>
            <select id="type" name="type"
                    class="form-select" v-model="form.type">
              <option value="input">Entrada</option>
              <option value="output">Saída</option>
            </select>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <button class="btn btn-primary mr-1"
                @click="save" :disabled="saving"
                :class="{loading: saving}">
          Salvar
        </button>
        <button class="btn" @click="$router.back()">
          Voltar
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/src/validators';
import formMixin from '../../../mixins/form';

export default {
  mixins: [formMixin],
  data() {
    return {
      isNew: false,
      loading: false,
      saving: false,
      form: this.blankForm(),
    };
  },
  validations() {
    return {
      form: {
        name: { required },
        type: { required },
      },
    };
  },
  async mounted() {
    this.isNew = /create$/.test(this.$route.path);
    if (!this.isNew) {
      this.form.id = this.$route.params.id;
      this.load();
    }
  },
  methods: {
    load() {
      this.loading = true;
      this.$http
        .get(`/operation-types/${this.form.id}`)
        .then(({ data }) => {
          this.form = data;
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false;
        });
    },
    async save() {
      this.$v.form.$touch();
      if (this.$v.form.$error) {
        return;
      }
      const params = {
        name: this.form.name,
      };

      const { data: found } = await this.$http.get('/operation-types', { params });

      if (found.items.length > 0 && found.items[0].id !== this.form.id) {
        this.$toast.show('Operação já adicionada', { type: 'error' });
        return;
      }

      this.saving = true;

      const operationType = this.clone(this.form);

      const request = this.isNew
        ? this.$http.post('/operation-types', operationType)
        : this.$http.put(`/operation-types/${this.form.id}`, operationType);

      // eslint-disable-next-line consistent-return
      return request
        .then(({ data }) => {
          if (this.isNew) {
            this.$router.replace(`/stock/operation-types/${data.id}/edit`);
            this.isNew = false;
            this.form.id = data.id;
          }
          this.$toast.show('Registro salvo');
        })
        .catch(() => {})
        .then(() => {
          this.saving = false;
        });
    },
    blankForm() {
      return {
        active: true,
        name: '',
        type: 'input',
      };
    },
  },
};
</script>

<style lang="scss">
@import "./src/assets/scss/_variables.scss";
.operation-type-page {
}
</style>
